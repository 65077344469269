import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, set } from "firebase/database";

import Cookies from 'universal-cookie';

import PopulateViews from "./PopulateViews";

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const cookies = new Cookies();

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDCLMh5wr1q3b0v7zCbulK1xrTSx6ZKfyw",
  authDomain: "anandgokul-com-fa2bf.firebaseapp.com",
  projectId: "anandgokul-com-fa2bf",
  storageBucket: "anandgokul-com-fa2bf.appspot.com",
  messagingSenderId: "891276679678",
  appId: "1:891276679678:web:bcc302dc0ab671fc5508af"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

let isfetchComplete = cookies.get('isCountSet') || false;

cookies.set('isCountSet', cookies.get('isCountSet') || 0, { path: '/' });

function readUserData(cb) {
  //console.log("Inside Read")
  const starCountRef = ref(db, 'viewcount/viewcount');
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    //console.log("Inside Onvalue " + data)
    cb(data);
  });
}

function writeUserData(updatedviewcount) {
  set(ref(db, 'viewcount/'), {
    viewcount: parseInt(updatedviewcount) + 1,
  });
}

const SideBar = () => {
  const [views, setViews] = useState(-1);
  let didUpdate = false;

  let getOldViews = () => {
    if (!didUpdate) {
      readUserData(result => {
        let result2 = parseInt(JSON.stringify(result))
        didUpdate = true
        if (cookies.get('isCountSet') == 0) {
          //console.log("Inside Condition " + result2)
          cookies.set('isCountSet', result2, { path: '/' });
          writeUserData(result2);
          isfetchComplete = true;
        }
      }
      )
    }
  }

  useEffect(() => {
    getOldViews()
  }, [])

  return (
    <section id="sidebar">
      <section id="intro">
        <Link to="/" className="logo">
          <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
        </Link>
        <header>
          <h2>Anand Gokul</h2>
          <p><a href="mailto:me@anandgokul.com">me@anandgokul.com</a></p>
        </header>
      </section>

      <section className="blurb">
        <h2>About</h2>
        <p>Hi, I&apos;m Anand. I like building things.
          I am a USC graduate with Masters in Computer Science, currently working as a Software Engineer/ Production Engineer at <a href="https://facebook.com"> Meta/ Facebook </a>. Before Meta, I worked as a Research Assistant at <a href="https://usc.edu">USC</a>, and as a Software Engineer at <a href="https://arista.com">Arista Networks</a>. I am also the founder of the <a href="https://networkingheart.com"> Networking Heart </a> blog.
        </p>
        <ul className="actions">
          <li>
            {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
          </li>
        </ul>
      </section>

      <div>{isfetchComplete ? <PopulateViews numViews={parseInt(cookies.get('isCountSet'))} /> : <></>}</div>

      <section id="footer">
        <ContactIcons />
        <p className="copyright">&copy; Anand Gokul <Link to="/">anandgokul.com</Link>.</p>
      </section>
    </section >);
}

export default SideBar;
